export const industryArr = [
    {
        label: "Software Development",
        value: "4",
    },
    {
        label: "IT Services and IT Consulting",
        value: "96",
    },
    {
        label: "Technology, Information and Internet",
        value: "6",
    },
    {
        label: "Strategic Management Services",
        value: "102",
    },
    {
        label: "Financial Services",
        value: "43",
    },
    {
        label: "Banking",
        value: "41",
    },
    {
        label: "Retail",
        value: "27",
    },
    {
        label: "Computer and Network Security",
        value: "118",
    },
    {
        label: "Advertising Services",
        value: "80",
    },
    {
        label: "Hospitals and Health Care",
        value: "14",
    },
    {
        label: "Chiropractors",
        value: "2048",
    },
    {
        label: "Defense and Space Manufacturing",
        value: "1",
    },
    {
        label: "Optometrists",
        value: "2050",
    },
    {
        label: "Computer Hardware Manufacturing",
        value: "3",
    },
    {
        label: "Computer Networking Products",
        value: "5",
    },
    {
        label: "Transportation Equipment Manufacturing",
        value: "1029",
    },
    {
        label: "Physical, Occupational and Speech Therapists",
        value: "2054",
    },
    {
        label: "Semiconductor Manufacturing",
        value: "7",
    },
    {
        label: "Telecommunications",
        value: "8",
    },
    {
        label: "Law Practice",
        value: "9",
    },
    {
        label: "Housing Programs",
        value: "3081",
    },
    {
        label: "Legal Services",
        value: "10",
    },
    {
        label: "Business Consulting and Services",
        value: "11",
    },
    {
        label: "Biotechnology Research",
        value: "12",
    },
    {
        label: "Family Planning Centers",
        value: "2060",
    },
    {
        label: "Medical Practices",
        value: "13",
    },
    {
        label: "Transportation Programs",
        value: "3085",
    },
    {
        label: "Utilities Administration",
        value: "3086",
    },
    {
        label: "Pharmaceutical Manufacturing",
        value: "15",
    },
    {
        label: "Outpatient Care Centers",
        value: "2063",
    },
    {
        label: "Veterinary Services",
        value: "16",
    },
    {
        label: "Medical Equipment Manufacturing",
        value: "17",
    },
    {
        label: "Space Research and Technology",
        value: "3089",
    },
    {
        label: "Motor Vehicle Parts Manufacturing",
        value: "1042",
    },
    {
        label: "Personal Care Product Manufacturing",
        value: "18",
    },
    {
        label: "Retail Apparel and Fashion",
        value: "19",
    },
    {
        label: "Sporting Goods Manufacturing",
        value: "20",
    },
    {
        label: "Tobacco Manufacturing",
        value: "21",
    },
    {
        label: "Medical and Diagnostic Laboratories",
        value: "2069",
    },
    {
        label: "Retail Groceries",
        value: "22",
    },
    {
        label: "Food and Beverage Manufacturing",
        value: "23",
    },
    {
        label: "Oil Extraction",
        value: "3095",
    },
    {
        label: "Computers and Electronics Manufacturing",
        value: "24",
    },
    {
        label: "Natural Gas Extraction",
        value: "3096",
    },
    {
        label: "Manufacturing",
        value: "25",
    },
    {
        label: "Furniture and Home Furnishings Manufacturing",
        value: "26",
    },
    {
        label: "Home Health Care Services",
        value: "2074",
    },
    {
        label: "Embedded Software Products",
        value: "3099",
    },
    {
        label: "Entertainment Providers",
        value: "28",
    },
    {
        label: "Mobile Computing Software Products",
        value: "3100",
    },
    {
        label: "Gambling Facilities and Casinos",
        value: "29",
    },
    {
        label: "Ambulance Services",
        value: "2077",
    },
    {
        label: "Desktop Computing Software Products",
        value: "3101",
    },
    {
        label: "IT System Custom Software Development",
        value: "3102",
    },
    {
        label: "Travel Arrangements",
        value: "30",
    },
    {
        label: "IT System Operations and Maintenance",
        value: "3103",
    },
    {
        label: "Hospitality",
        value: "31",
    },
    {
        label: "IT System Installation and Disposal",
        value: "3104",
    },
    {
        label: "Restaurants",
        value: "32",
    },
    {
        label: "IT System Training and Support",
        value: "3105",
    },
    {
        label: "Hospitals",
        value: "2081",
    },
    {
        label: "Spectator Sports",
        value: "33",
    },
    {
        label: "IT System Data Services",
        value: "3106",
    },
    {
        label: "Food and Beverage Services",
        value: "34",
    },
    {
        label: "IT System Testing and Evaluation",
        value: "3107",
    },
    {
        label: "Movies, Videos, and Sound",
        value: "35",
    },
    {
        label: "Broadcast Media Production and Distribution",
        value: "36",
    },
    {
        label: "Museums, Historical Sites, and Zoos",
        value: "37",
    },
    {
        label: "Artists and Writers",
        value: "38",
    },
    {
        label: "Performing Arts",
        value: "39",
    },
    {
        label: "Recreational Facilities",
        value: "40",
    },
    {
        label: "Insurance",
        value: "42",
    },
    {
        label: "Nursing Homes and Residential Care Facilities",
        value: "2091",
    },
    {
        label: "Real Estate",
        value: "44",
    },
    {
        label: "Investment Banking",
        value: "45",
    },
    {
        label: "Investment Management",
        value: "46",
    },
    {
        label: "Accounting",
        value: "47",
    },
    {
        label: "Construction",
        value: "48",
    },
    {
        label: "Wholesale Building Materials",
        value: "49",
    },
    {
        label: "Architecture and Planning",
        value: "50",
    },
    {
        label: "Civil Engineering",
        value: "51",
    },
    {
        label: "Internet News",
        value: "3124",
    },
    {
        label: "Aviation and Aerospace Component Manufacturing",
        value: "52",
    },
    {
        label: "Blogs",
        value: "3125",
    },
    {
        label: "Motor Vehicle Manufacturing",
        value: "53",
    },
    {
        label: "Interior Design",
        value: "3126",
    },
    {
        label: "Chemical Manufacturing",
        value: "54",
    },
    {
        label: "Social Networking Platforms",
        value: "3127",
    },
    {
        label: "Machinery Manufacturing",
        value: "55",
    },
    {
        label: "Household and Institutional Furniture Manufacturing",
        value: "1080",
    },
    {
        label: "Business Intelligence Platforms",
        value: "3128",
    },
    {
        label: "Mining",
        value: "56",
    },
    {
        label: "Business Content",
        value: "3129",
    },
    {
        label: "Oil and Gas",
        value: "57",
    },
    {
        label: "Data Security Software Products",
        value: "3130",
    },
    {
        label: "Shipbuilding",
        value: "58",
    },
    {
        label: "Utilities",
        value: "59",
    },
    {
        label: "Mobile Gaming Apps",
        value: "3131",
    },
    {
        label: "Textile Manufacturing",
        value: "60",
    },
    {
        label: "Internet Publishing",
        value: "3132",
    },
    {
        label: "Paper and Forest Product Manufacturing",
        value: "61",
    },
    {
        label: "Media and Telecommunications",
        value: "3133",
    },
    {
        label: "Railroad Equipment Manufacturing",
        value: "62",
    },
    {
        label: "Blockchain Services",
        value: "3134",
    },
    {
        label: "Farming",
        value: "63",
    },
    {
        label: "Services for the Elderly and Disabled",
        value: "2112",
    },
    {
        label: "Ranching",
        value: "64",
    },
    {
        label: "Dairy Product Manufacturing",
        value: "65",
    },
    {
        label: "Office Furniture and Fixtures Manufacturing",
        value: "1090",
    },
    {
        label: "Fisheries",
        value: "66",
    },
    {
        label: "Community Services",
        value: "2115",
    },
    {
        label: "Primary and Secondary Education",
        value: "67",
    },
    {
        label: "Higher Education",
        value: "68",
    },
    {
        label: "Education Administration Programs",
        value: "69",
    },
    {
        label: "Research Services",
        value: "70",
    },
    {
        label: "Mattress and Blinds Manufacturing",
        value: "1095",
    },
    {
        label: "Armed Forces",
        value: "71",
    },
    {
        label: "Legislative Offices",
        value: "72",
    },
    {
        label: "Administration of Justice",
        value: "73",
    },
    {
        label: "International Affairs",
        value: "74",
    },
    {
        label: "Emergency and Relief Services",
        value: "2122",
    },
    {
        label: "Government Administration",
        value: "75",
    },
    {
        label: "Executive Offices",
        value: "76",
    },
    {
        label: "Law Enforcement",
        value: "77",
    },
    {
        label: "Vocational Rehabilitation Services",
        value: "2125",
    },
    {
        label: "Public Safety",
        value: "78",
    },
    {
        label: "Public Policy Offices",
        value: "79",
    },
    {
        label: "Child Day Care Services",
        value: "2128",
    },
    {
        label: "Newspaper Publishing",
        value: "81",
    },
    {
        label: "Performing Arts and Spectator Sports",
        value: "2130",
    },
    {
        label: "Book and Periodical Publishing",
        value: "82",
    },
    {
        label: "Printing Services",
        value: "83",
    },
    {
        label: "Information Services",
        value: "84",
    },
    {
        label: "Libraries",
        value: "85",
    },
    {
        label: "Theater Companies",
        value: "2133",
    },
    {
        label: "Environmental Services",
        value: "86",
    },
    {
        label: "Freight and Package Transportation",
        value: "87",
    },
    {
        label: "Dance Companies",
        value: "2135",
    },
    {
        label: "Individual and Family Services",
        value: "88",
    },
    {
        label: "Religious Institutions",
        value: "89",
    },
    {
        label: "Civic and Social Organizations",
        value: "90",
    },
    {
        label: "Consumer Services",
        value: "91",
    },
    {
        label: "Circuses and Magic Shows",
        value: "2139",
    },
    {
        label: "Truck Transportation",
        value: "92",
    },
    {
        label: "Warehousing and Storage",
        value: "93",
    },
    {
        label: "Sports Teams and Clubs",
        value: "2142",
    },
    {
        label: "Airlines and Aviation",
        value: "94",
    },
    {
        label: "Maritime Transportation",
        value: "95",
    },
    {
        label: "Racetracks",
        value: "2143",
    },
    {
        label: "Market Research",
        value: "97",
    },
    {
        label: "Public Relations and Communications Services",
        value: "98",
    },
    {
        label: "Design Services",
        value: "99",
    },
    {
        label: "Non-profit Organizations",
        value: "100",
    },
    {
        label: "Fundraising",
        value: "101",
    },
    {
        label: "Writing and Editing",
        value: "103",
    },
    {
        label: "Staffing and Recruiting",
        value: "104",
    },
    {
        label: "Wholesale Motor Vehicles and Parts",
        value: "1128",
    },
    {
        label: "Professional Training and Coaching",
        value: "105",
    },
    {
        label: "Venture Capital and Private Equity Principals",
        value: "106",
    },
    {
        label: "Political Organizations",
        value: "107",
    },
    {
        label: "Translation and Localization",
        value: "108",
    },
    {
        label: "Computer Games",
        value: "109",
    },
    {
        label: "Events Services",
        value: "110",
    },
    {
        label: "Retail Art Supplies",
        value: "111",
    },
    {
        label: "Museums",
        value: "2159",
    },
    {
        label: "Appliances, Electrical, and Electronics Manufacturing",
        value: "112",
    },
    {
        label: "Online Audio and Video Media",
        value: "113",
    },
    {
        label: "Wholesale Furniture and Home Furnishings",
        value: "1137",
    },
    {
        label: "Historical Sites",
        value: "2161",
    },
    {
        label: "Nanotechnology Research",
        value: "114",
    },
    {
        label: "Retail Art Dealers",
        value: "3186",
    },
    {
        label: "Musicians",
        value: "115",
    },
    {
        label: "Zoos and Botanical Gardens",
        value: "2163",
    },
    {
        label: "Transportation, Logistics, Supply Chain and Storage",
        value: "116",
    },
    {
        label: "Plastics Manufacturing",
        value: "117",
    },
    {
        label: "Wireless Services",
        value: "119",
    },
    {
        label: "Amusement Parks and Arcades",
        value: "2167",
    },
    {
        label: "Alternative Dispute Resolution",
        value: "120",
    },
    {
        label: "Security and Investigations",
        value: "121",
    },
    {
        label: "Facilities Services",
        value: "122",
    },
    {
        label: "Outsourcing and Offshoring Consulting",
        value: "123",
    },
    {
        label: "Wellness and Fitness Services",
        value: "124",
    },
    {
        label: "Alternative Medicine",
        value: "125",
    },
    {
        label: "Media Production",
        value: "126",
    },
    {
        label: "Animation and Post-production",
        value: "127",
    },
    {
        label: "Leasing Non-residential Real Estate",
        value: "128",
    },
    {
        label: "Capital Markets",
        value: "129",
    },
    {
        label: "Wholesale Photography Equipment and Supplies",
        value: "1153",
    },
    {
        label: "Think Tanks",
        value: "130",
    },
    {
        label: "Philanthropic Fundraising Services",
        value: "131",
    },
    {
        label: "Golf Courses and Country Clubs",
        value: "2179",
    },
    {
        label: "E-Learning Providers",
        value: "132",
    },
    {
        label: "Wholesale",
        value: "133",
    },
    {
        label: "Wholesale Computer Equipment",
        value: "1157",
    },
    {
        label: "Skiing Facilities",
        value: "2181",
    },
    {
        label: "Wholesale Import and Export",
        value: "134",
    },
    {
        label: "Industrial Machinery Manufacturing",
        value: "135",
    },
    {
        label: "Photography",
        value: "136",
    },
    {
        label: "Human Resources Services",
        value: "137",
    },
    {
        label: "Retail Office Equipment",
        value: "138",
    },
    {
        label: "Mental Health Care",
        value: "139",
    },
    {
        label: "Graphic Design",
        value: "140",
    },
    {
        label: "International Trade and Development",
        value: "141",
    },
    {
        label: "Beverage Manufacturing",
        value: "142",
    },
    {
        label: "Accommodation and Food Services",
        value: "2190",
    },
    {
        label: "Wholesale Metals and Minerals",
        value: "1166",
    },
    {
        label: "Retail Luxury Goods and Jewelry",
        value: "143",
    },
    {
        label: "Renewable Energy Semiconductor Manufacturing",
        value: "144",
    },
    {
        label: "Glass, Ceramics and Concrete Manufacturing",
        value: "145",
    },
    {
        label: "Packaging and Containers Manufacturing",
        value: "146",
    },
    {
        label: "Hotels and Motels",
        value: "2194",
    },
    {
        label: "Automation Machinery Manufacturing",
        value: "147",
    },
    {
        label: "Wholesale Appliances, Electrical, and Electronics",
        value: "1171",
    },
    {
        label: "Government Relations Services",
        value: "148",
    },
    {
        label: "Bed-and-Breakfasts, Hostels, Homestays",
        value: "2197",
    },
    {
        label: "Horticulture",
        value: "150",
    },
    {
        label: "Wholesale Hardware, Plumbing, Heating Equipment",
        value: "1178",
    },
    {
        label: "Wholesale Machinery",
        value: "1187",
    },
    {
        label: "Caterers",
        value: "2212",
    },
    {
        label: "Mobile Food Services",
        value: "2214",
    },
    {
        label: "Renewable Energy Power Generation",
        value: "3240",
    },
    {
        label: "Bars, Taverns, and Nightclubs",
        value: "2217",
    },
    {
        label: "Renewable Energy Equipment Manufacturing",
        value: "3241",
    },
    {
        label: "Engineering Services",
        value: "3242",
    },
    {
        label: "Services for Renewable Energy",
        value: "3243",
    },
    {
        label: "Digital Accessibility Services",
        value: "3244",
    },
    {
        label: "Accessible Hardware Manufacturing",
        value: "3245",
    },
    {
        label: "Accessible Architecture and Design",
        value: "3246",
    },
    {
        label: "Robot Manufacturing",
        value: "3247",
    },
    {
        label: "Robotics Engineering",
        value: "3248",
    },
    {
        label: "Repair and Maintenance",
        value: "2225",
    },
    {
        label: "Surveying and Mapping Services",
        value: "3249",
    },
    {
        label: "Vehicle Repair and Maintenance",
        value: "2226",
    },
    {
        label: "Retail Pharmacies",
        value: "3250",
    },
    {
        label: "Climate Technology Product Manufacturing",
        value: "3251",
    },
    {
        label: "Climate Data and Analytics",
        value: "3252",
    },
    {
        label: "Alternative Fuel Vehicle Manufacturing",
        value: "3253",
    },
    {
        label: "Wholesale Recyclable Materials",
        value: "1206",
    },
    {
        label: "Smart Meter Manufacturing",
        value: "3254",
    },
    {
        label: "Fuel Cell Manufacturing",
        value: "3255",
    },
    {
        label: "Wholesale Luxury Goods and Jewelry",
        value: "1208",
    },
    {
        label: "Regenerative Design",
        value: "3256",
    },
    {
        label: "Wholesale Paper Products",
        value: "1212",
    },
    {
        label: "Electronic and Precision Equipment Maintenance",
        value: "2240",
    },
    {
        label: "Wholesale Drugs and Sundries",
        value: "1221",
    },
    {
        label: "Wholesale Apparel and Sewing Supplies",
        value: "1222",
    },
    {
        label: "Commercial and Industrial Machinery Maintenance",
        value: "2247",
    },
    {
        label: "Farming, Ranching, Forestry",
        value: "201",
    },
    {
        label: "Reupholstery and Furniture Repair",
        value: "2253",
    },
    {
        label: "Wholesale Footwear",
        value: "1230",
    },
    {
        label: "Wholesale Food and Beverage",
        value: "1231",
    },
    {
        label: "Footwear and Leather Goods Repair",
        value: "2255",
    },
    {
        label: "Personal and Laundry Services",
        value: "2258",
    },
    {
        label: "Personal Care Services",
        value: "2259",
    },
    {
        label: "Laundry and Drycleaning Services",
        value: "2272",
    },
    {
        label: "Wholesale Raw Farm Products",
        value: "1250",
    },
    {
        label: "Wholesale Chemical and Allied Products",
        value: "1257",
    },
    {
        label: "Pet Services",
        value: "2282",
    },
    {
        label: "Wholesale Petroleum and Petroleum Products",
        value: "1262",
    },
    {
        label: "Wholesale Alcoholic Beverages",
        value: "1267",
    },
    {
        label: "Ranching and Fisheries",
        value: "256",
    },
    {
        label: "Internet Marketplace Platforms",
        value: "1285",
    },
    {
        label: "Retail Motor Vehicles",
        value: "1292",
    },
    {
        label: "Household Services",
        value: "2318",
    },
    {
        label: "Retail Furniture and Home Furnishings",
        value: "1309",
    },
    {
        label: "Retail Appliances, Electrical, and Electronic Equipment",
        value: "1319",
    },
    {
        label: "Forestry and Logging",
        value: "298",
    },
    {
        label: "Retail Building Materials and Garden Equipment",
        value: "1324",
    },
    {
        label: "Health and Human Services",
        value: "2353",
    },
    {
        label: "Public Health",
        value: "2358",
    },
    {
        label: "Public Assistance Programs",
        value: "2360",
    },
    {
        label: "Food and Beverage Retail",
        value: "1339",
    },
    {
        label: "Air, Water, and Waste Program Management",
        value: "2366",
    },
    {
        label: "Conservation Programs",
        value: "2368",
    },
    {
        label: "Housing and Community Development",
        value: "2369",
    },
    {
        label: "Community Development and Urban Planning",
        value: "2374",
    },
    {
        label: "Economic Programs",
        value: "2375",
    },
    {
        label: "Oil, Gas, and Mining",
        value: "332",
    },
    {
        label: "Retail Health and Personal Care Products",
        value: "1359",
    },
    {
        label: "Coal Mining",
        value: "341",
    },
    {
        label: "Military and International Affairs",
        value: "2391",
    },
    {
        label: "Metal Ore Mining",
        value: "345",
    },
    {
        label: "Retail Gasoline",
        value: "1370",
    },
    {
        label: "Operations Consulting",
        value: "2401",
    },
    {
        label: "Nonmetallic Mineral Mining",
        value: "356",
    },
    {
        label: "Electric Power Transmission, Control, and Distribution",
        value: "382",
    },
    {
        label: "Retail Musical Instruments",
        value: "1407",
    },
    {
        label: "Electric Power Generation",
        value: "383",
    },
    {
        label: "Hydroelectric Power Generation",
        value: "384",
    },
    {
        label: "Retail Books and Printed News",
        value: "1409",
    },
    {
        label: "Fossil Fuel Electric Power Generation",
        value: "385",
    },
    {
        label: "Nuclear Electric Power Generation",
        value: "386",
    },
    {
        label: "Solar Electric Power Generation",
        value: "387",
    },
    {
        label: "Environmental Quality Programs",
        value: "388",
    },
    {
        label: "Geothermal Electric Power Generation",
        value: "389",
    },
    {
        label: "Biomass Electric Power Generation",
        value: "390",
    },
    {
        label: "Natural Gas Distribution",
        value: "397",
    },
    {
        label: "Water, Waste, Steam, and Air Conditioning Services",
        value: "398",
    },
    {
        label: "Retail Florists",
        value: "1423",
    },
    {
        label: "Retail Office Supplies and Gifts",
        value: "1424",
    },
    {
        label: "Water Supply and Irrigation Systems",
        value: "400",
    },
    {
        label: "Steam and Air-Conditioning Supply",
        value: "404",
    },
    {
        label: "Building Construction",
        value: "406",
    },
    {
        label: "Retail Recyclable Materials & Used Merchandise",
        value: "1431",
    },
    {
        label: "Residential Building Construction",
        value: "408",
    },
    {
        label: "Data Infrastructure and Analytics",
        value: "2458",
    },
    {
        label: "Nonresidential Building Construction",
        value: "413",
    },
    {
        label: "Utility System Construction",
        value: "419",
    },
    {
        label: "Electrical Equipment Manufacturing",
        value: "2468",
    },
    {
        label: "Online and Mail Order Retail",
        value: "1445",
    },
    {
        label: "Subdivision of Land",
        value: "428",
    },
    {
        label: "Highway, Street, and Bridge Construction",
        value: "431",
    },
    {
        label: "Specialty Trade Contractors",
        value: "435",
    },
    {
        label: "Building Structure and Exterior Contractors",
        value: "436",
    },
    {
        label: "Wind Electric Power Generation",
        value: "2489",
    },
    {
        label: "Wineries",
        value: "2500",
    },
    {
        label: "Building Equipment Contractors",
        value: "453",
    },
    {
        label: "Rail Transportation",
        value: "1481",
    },
    {
        label: "Building Finishing Contractors",
        value: "460",
    },
    {
        label: "Ground Passenger Transportation",
        value: "1495",
    },
    {
        label: "Urban Transit Services",
        value: "1497",
    },
    {
        label: "Interurban and Rural Bus Services",
        value: "1504",
    },
    {
        label: "Taxi and Limousine Services",
        value: "1505",
    },
    {
        label: "Animal Feed Manufacturing",
        value: "481",
    },
    {
        label: "School and Employee Bus Services",
        value: "1512",
    },
    {
        label: "Shuttles and Special Needs Transportation Services",
        value: "1517",
    },
    {
        label: "Sugar and Confectionery Product Manufacturing",
        value: "495",
    },
    {
        label: "Pipeline Transportation",
        value: "1520",
    },
    {
        label: "Fruit and Vegetable Preserves Manufacturing",
        value: "504",
    },
    {
        label: "Sightseeing Transportation",
        value: "1532",
    },
    {
        label: "Meat Products Manufacturing",
        value: "521",
    },
    {
        label: "Seafood Product Manufacturing",
        value: "528",
    },
    {
        label: "Baked Goods Manufacturing",
        value: "529",
    },
    {
        label: "Postal Services",
        value: "1573",
    },
    {
        label: "Breweries",
        value: "562",
    },
    {
        label: "Distilleries",
        value: "564",
    },
    {
        label: "Technology, Information and Media",
        value: "1594",
    },
    {
        label: "Periodical Publishing",
        value: "1600",
    },
    {
        label: "Book Publishing",
        value: "1602",
    },
    {
        label: "Movies and Sound Recording",
        value: "1611",
    },
    {
        label: "Apparel Manufacturing",
        value: "598",
    },
    {
        label: "Sound Recording",
        value: "1623",
    },
    {
        label: "Sheet Music Publishing",
        value: "1625",
    },
    {
        label: "Radio and Television Broadcasting",
        value: "1633",
    },
    {
        label: "Fashion Accessories Manufacturing",
        value: "615",
    },
    {
        label: "Leather Product Manufacturing",
        value: "616",
    },
    {
        label: "Cable and Satellite Programming",
        value: "1641",
    },
    {
        label: "Telecommunications Carriers",
        value: "1644",
    },
    {
        label: "Footwear Manufacturing",
        value: "622",
    },
    {
        label: "Satellite Telecommunications",
        value: "1649",
    },
    {
        label: "Women's Handbag Manufacturing",
        value: "625",
    },
    {
        label: "Credit Intermediation",
        value: "1673",
    },
    {
        label: "Savings Institutions",
        value: "1678",
    },
    {
        label: "Loan Brokers",
        value: "1696",
    },
    {
        label: "Oil and Coal Product Manufacturing",
        value: "679",
    },
    {
        label: "Securities and Commodity Exchanges",
        value: "1713",
    },
    {
        label: "Chemical Raw Materials Manufacturing",
        value: "690",
    },
    {
        label: "Investment Advice",
        value: "1720",
    },
    {
        label: "Insurance Carriers",
        value: "1725",
    },
    {
        label: "Artificial Rubber and Synthetic Fiber Manufacturing",
        value: "703",
    },
    {
        label: "Agricultural Chemical Manufacturing",
        value: "709",
    },
    {
        label: "Insurance Agencies and Brokerages",
        value: "1737",
    },
    {
        label: "Claims Adjusting, Actuarial Services",
        value: "1738",
    },
    {
        label: "Funds and Trusts",
        value: "1742",
    },
    {
        label: "Insurance and Employee Benefit Funds",
        value: "1743",
    },
    {
        label: "Pension Funds",
        value: "1745",
    },
    {
        label: "Paint, Coating, and Adhesive Manufacturing",
        value: "722",
    },
    {
        label: "Trusts and Estates",
        value: "1750",
    },
    {
        label: "Soap and Cleaning Product Manufacturing",
        value: "727",
    },
    {
        label: "Real Estate and Equipment Rental Services",
        value: "1757",
    },
    {
        label: "Leasing Residential Real Estate",
        value: "1759",
    },
    {
        label: "Plastics and Rubber Product Manufacturing",
        value: "743",
    },
    {
        label: "Real Estate Agents and Brokers",
        value: "1770",
    },
    {
        label: "Equipment Rental Services",
        value: "1779",
    },
    {
        label: "Consumer Goods Rental",
        value: "1786",
    },
    {
        label: "Rubber Products Manufacturing",
        value: "763",
    },
    {
        label: "Clay and Refractory Products Manufacturing",
        value: "773",
    },
    {
        label: "Commercial and Industrial Equipment Rental",
        value: "1798",
    },
    {
        label: "Glass Product Manufacturing",
        value: "779",
    },
    {
        label: "Wood Product Manufacturing",
        value: "784",
    },
    {
        label: "Professional Services",
        value: "1810",
    },
    {
        label: "Lime and Gypsum Products Manufacturing",
        value: "794",
    },
    {
        label: "Abrasives and Nonmetallic Minerals Manufacturing",
        value: "799",
    },
    {
        label: "Primary Metal Manufacturing",
        value: "807",
    },
    {
        label: "IT System Design Services",
        value: "1855",
    },
    {
        label: "Marketing Services",
        value: "1862",
    },
    {
        label: "Fabricated Metal Products",
        value: "840",
    },
    {
        label: "Cutlery and Handtool Manufacturing",
        value: "849",
    },
    {
        label: "Architectural and Structural Metal Manufacturing",
        value: "852",
    },
    {
        label: "Boilers, Tanks, and Shipping Container Manufacturing",
        value: "861",
    },
    {
        label: "Construction Hardware Manufacturing",
        value: "871",
    },
    {
        label: "Spring and Wire Product Manufacturing",
        value: "873",
    },
    {
        label: "Turned Products and Fastener Manufacturing",
        value: "876",
    },
    {
        label: "Holding Companies",
        value: "1905",
    },
    {
        label: "Metal Treatments",
        value: "883",
    },
    {
        label: "Industry Associations",
        value: "1909",
    },
    {
        label: "Landscaping Services",
        value: "2934",
    },
    {
        label: "Professional Organizations",
        value: "1911",
    },
    {
        label: "Metal Valve, Ball, and Roller Manufacturing",
        value: "887",
    },
    {
        label: "Administrative and Support Services",
        value: "1912",
    },
    {
        label: "Office Administration",
        value: "1916",
    },
    {
        label: "Executive Search Services",
        value: "1923",
    },
    {
        label: "Temporary Help Services",
        value: "1925",
    },
    {
        label: "Agriculture, Construction, Mining Machinery Manufacturing",
        value: "901",
    },
    {
        label: "Telephone Call Centers",
        value: "1931",
    },
    {
        label: "Collection Agencies",
        value: "1938",
    },
    {
        label: "Commercial and Service Industry Machinery Manufacturing",
        value: "918",
    },
    {
        label: "HVAC and Refrigeration Equipment Manufacturing",
        value: "923",
    },
    {
        label: "Metalworking Machinery Manufacturing",
        value: "928",
    },
    {
        label: "Security Guards and Patrol Services",
        value: "1956",
    },
    {
        label: "Security Systems Services",
        value: "1958",
    },
    {
        label: "Engines and Power Transmission Equipment Manufacturing",
        value: "935",
    },
    {
        label: "Janitorial Services",
        value: "1965",
    },
    {
        label: "Waste Collection",
        value: "1981",
    },
    {
        label: "Waste Treatment and Disposal",
        value: "1986",
    },
    {
        label: "Communications Equipment Manufacturing",
        value: "964",
    },
    {
        label: "Audio and Video Equipment Manufacturing",
        value: "973",
    },
    {
        label: "Education",
        value: "1999",
    },
    {
        label: "Measuring and Control Instrument Manufacturing",
        value: "983",
    },
    {
        label: "Secretarial Schools",
        value: "2012",
    },
    {
        label: "Technical and Vocational Training",
        value: "2018",
    },
    {
        label: "Magnetic and Optical Media Manufacturing",
        value: "994",
    },
    {
        label: "Cosmetology and Barber Schools",
        value: "2019",
    },
    {
        label: "Flight Training",
        value: "2020",
    },
    {
        label: "Electric Lighting Equipment Manufacturing",
        value: "998",
    },
    {
        label: "Fine Arts Schools",
        value: "2025",
    },
    {
        label: "Sports and Recreation Instruction",
        value: "2027",
    },
    {
        label: "Household Appliance Manufacturing",
        value: "1005",
    },
    {
        label: "Language Schools",
        value: "2029",
    },
    {
        label: "Physicians",
        value: "2040",
    },
    {
        label: "Courts of Law",
        value: "3065",
    },
    {
        label: "Correctional Institutions",
        value: "3068",
    },
    {
        label: "Dentists",
        value: "2045",
    },
    {
        label: "Fire Protection",
        value: "3070",
    },
];

export const jobFunctionArr = [
    {
        label: "Business Development",
        value: "4",
    },
    {
        label: "Engineering",
        value: "8",
    },
    {
        label: "Accounting",
        value: "1",
    },
    {
        label: "Administrative",
        value: "2",
    },
    {
        label: "Arts and Design",
        value: "3",
    },
    {
        label: "Community and Social Services",
        value: "5",
    },
    {
        label: "Consulting",
        value: "6",
    },
    {
        label: "Education",
        value: "7",
    },
    {
        label: "Entrepreneurship",
        value: "9",
    },
    {
        label: "Finance",
        value: "10",
    },
    {
        label: "Healthcare Services",
        value: "11",
    },
    {
        label: "Human Resources",
        value: "12",
    },
    {
        label: "Information Technology",
        value: "13",
    },
    {
        label: "Legal",
        value: "14",
    },
    {
        label: "Marketing",
        value: "15",
    },
    {
        label: "Media and Communication",
        value: "16",
    },
    {
        label: "Military and Protective Services",
        value: "17",
    },
    {
        label: "Operations",
        value: "18",
    },
    {
        label: "Product Management",
        value: "19",
    },
    {
        label: "Program and Project Management",
        value: "20",
    },
    {
        label: "Purchasing",
        value: "21",
    },
    {
        label: "Quality Assurance",
        value: "22",
    },
    {
        label: "Real Estate",
        value: "23",
    },
    {
        label: "Research",
        value: "24",
    },
    {
        label: "Sales",
        value: "25",
    },
    {
        label: "Customer Success and Support",
        value: "26",
    },
];

export const industryOptions = {
    "Software Development": "4",
    "IT Services and IT Consulting": "96",
    "Technology, Information and Internet": "6",
    "Strategic Management Services": "102",
    "Financial Services": "43",
    Banking: "41",
    Retail: "27",
    "Computer and Network Security": "118",
    "Advertising Services": "80",
    "Hospitals and Health Care": "14",
    Chiropractors: "2048",
    "Defense and Space Manufacturing": "1",
    Optometrists: "2050",
    "Computer Hardware Manufacturing": "3",
    "Computer Networking Products": "5",
    "Transportation Equipment Manufacturing": "1029",
    "Physical, Occupational and Speech Therapists": "2054",
    "Semiconductor Manufacturing": "7",
    Telecommunications: "8",
    "Law Practice": "9",
    "Housing Programs": "3081",
    "Legal Services": "10",
    "Business Consulting and Services": "11",
    "Biotechnology Research": "12",
    "Family Planning Centers": "2060",
    "Medical Practices": "13",
    "Transportation Programs": "3085",
    "Utilities Administration": "3086",
    "Pharmaceutical Manufacturing": "15",
    "Outpatient Care Centers": "2063",
    "Veterinary Services": "16",
    "Medical Equipment Manufacturing": "17",
    "Space Research and Technology": "3089",
    "Motor Vehicle Parts Manufacturing": "1042",
    "Personal Care Product Manufacturing": "18",
    "Retail Apparel and Fashion": "19",
    "Sporting Goods Manufacturing": "20",
    "Tobacco Manufacturing": "21",
    "Medical and Diagnostic Laboratories": "2069",
    "Retail Groceries": "22",
    "Food and Beverage Manufacturing": "23",
    "Oil Extraction": "3095",
    "Computers and Electronics Manufacturing": "24",
    "Natural Gas Extraction": "3096",
    Manufacturing: "25",
    "Furniture and Home Furnishings Manufacturing": "26",
    "Home Health Care Services": "2074",
    "Embedded Software Products": "3099",
    "Entertainment Providers": "28",
    "Mobile Computing Software Products": "3100",
    "Gambling Facilities and Casinos": "29",
    "Ambulance Services": "2077",
    "Desktop Computing Software Products": "3101",
    "IT System Custom Software Development": "3102",
    "Travel Arrangements": "30",
    "IT System Operations and Maintenance": "3103",
    Hospitality: "31",
    "IT System Installation and Disposal": "3104",
    Restaurants: "32",
    "IT System Training and Support": "3105",
    Hospitals: "2081",
    "Spectator Sports": "33",
    "IT System Data Services": "3106",
    "Food and Beverage Services": "34",
    "IT System Testing and Evaluation": "3107",
    "Movies, Videos, and Sound": "35",
    "Broadcast Media Production and Distribution": "36",
    "Museums, Historical Sites, and Zoos": "37",
    "Artists and Writers": "38",
    "Performing Arts": "39",
    "Recreational Facilities": "40",
    Insurance: "42",
    "Nursing Homes and Residential Care Facilities": "2091",
    "Real Estate": "44",
    "Investment Banking": "45",
    "Investment Management": "46",
    Accounting: "47",
    Construction: "48",
    "Wholesale Building Materials": "49",
    "Architecture and Planning": "50",
    "Civil Engineering": "51",
    "Internet News": "3124",
    "Aviation and Aerospace Component Manufacturing": "52",
    Blogs: "3125",
    "Motor Vehicle Manufacturing": "53",
    "Interior Design": "3126",
    "Chemical Manufacturing": "54",
    "Social Networking Platforms": "3127",
    "Machinery Manufacturing": "55",
    "Household and Institutional Furniture Manufacturing": "1080",
    "Business Intelligence Platforms": "3128",
    Mining: "56",
    "Business Content": "3129",
    "Oil and Gas": "57",
    "Data Security Software Products": "3130",
    Shipbuilding: "58",
    Utilities: "59",
    "Mobile Gaming Apps": "3131",
    "Textile Manufacturing": "60",
    "Internet Publishing": "3132",
    "Paper and Forest Product Manufacturing": "61",
    "Media and Telecommunications": "3133",
    "Railroad Equipment Manufacturing": "62",
    "Blockchain Services": "3134",
    Farming: "63",
    "Services for the Elderly and Disabled": "2112",
    Ranching: "64",
    "Dairy Product Manufacturing": "65",
    "Office Furniture and Fixtures Manufacturing": "1090",
    Fisheries: "66",
    "Community Services": "2115",
    "Primary and Secondary Education": "67",
    "Higher Education": "68",
    "Education Administration Programs": "69",
    "Research Services": "70",
    "Mattress and Blinds Manufacturing": "1095",
    "Armed Forces": "71",
    "Legislative Offices": "72",
    "Administration of Justice": "73",
    "International Affairs": "74",
    "Emergency and Relief Services": "2122",
    "Government Administration": "75",
    "Executive Offices": "76",
    "Law Enforcement": "77",
    "Vocational Rehabilitation Services": "2125",
    "Public Safety": "78",
    "Public Policy Offices": "79",
    "Child Day Care Services": "2128",
    "Newspaper Publishing": "81",
    "Performing Arts and Spectator Sports": "2130",
    "Book and Periodical Publishing": "82",
    "Printing Services": "83",
    "Information Services": "84",
    Libraries: "85",
    "Theater Companies": "2133",
    "Environmental Services": "86",
    "Freight and Package Transportation": "87",
    "Dance Companies": "2135",
    "Individual and Family Services": "88",
    "Religious Institutions": "89",
    "Civic and Social Organizations": "90",
    "Consumer Services": "91",
    "Circuses and Magic Shows": "2139",
    "Truck Transportation": "92",
    "Warehousing and Storage": "93",
    "Sports Teams and Clubs": "2142",
    "Airlines and Aviation": "94",
    "Maritime Transportation": "95",
    Racetracks: "2143",
    "Market Research": "97",
    "Public Relations and Communications Services": "98",
    "Design Services": "99",
    "Non-profit Organizations": "100",
    Fundraising: "101",
    "Writing and Editing": "103",
    "Staffing and Recruiting": "104",
    "Wholesale Motor Vehicles and Parts": "1128",
    "Professional Training and Coaching": "105",
    "Venture Capital and Private Equity Principals": "106",
    "Political Organizations": "107",
    "Translation and Localization": "108",
    "Computer Games": "109",
    "Events Services": "110",
    "Retail Art Supplies": "111",
    Museums: "2159",
    "Appliances, Electrical, and Electronics Manufacturing": "112",
    "Online Audio and Video Media": "113",
    "Wholesale Furniture and Home Furnishings": "1137",
    "Historical Sites": "2161",
    "Nanotechnology Research": "114",
    "Retail Art Dealers": "3186",
    Musicians: "115",
    "Zoos and Botanical Gardens": "2163",
    "Transportation, Logistics, Supply Chain and Storage": "116",
    "Plastics Manufacturing": "117",
    "Wireless Services": "119",
    "Amusement Parks and Arcades": "2167",
    "Alternative Dispute Resolution": "120",
    "Security and Investigations": "121",
    "Facilities Services": "122",
    "Outsourcing and Offshoring Consulting": "123",
    "Wellness and Fitness Services": "124",
    "Alternative Medicine": "125",
    "Media Production": "126",
    "Animation and Post-production": "127",
    "Leasing Non-residential Real Estate": "128",
    "Capital Markets": "129",
    "Wholesale Photography Equipment and Supplies": "1153",
    "Think Tanks": "130",
    "Philanthropic Fundraising Services": "131",
    "Golf Courses and Country Clubs": "2179",
    "E-Learning Providers": "132",
    Wholesale: "133",
    "Wholesale Computer Equipment": "1157",
    "Skiing Facilities": "2181",
    "Wholesale Import and Export": "134",
    "Industrial Machinery Manufacturing": "135",
    Photography: "136",
    "Human Resources Services": "137",
    "Retail Office Equipment": "138",
    "Mental Health Care": "139",
    "Graphic Design": "140",
    "International Trade and Development": "141",
    "Beverage Manufacturing": "142",
    "Accommodation and Food Services": "2190",
    "Wholesale Metals and Minerals": "1166",
    "Retail Luxury Goods and Jewelry": "143",
    "Renewable Energy Semiconductor Manufacturing": "144",
    "Glass, Ceramics and Concrete Manufacturing": "145",
    "Packaging and Containers Manufacturing": "146",
    "Hotels and Motels": "2194",
    "Automation Machinery Manufacturing": "147",
    "Wholesale Appliances, Electrical, and Electronics": "1171",
    "Government Relations Services": "148",
    "Bed-and-Breakfasts, Hostels, Homestays": "2197",
    Horticulture: "150",
    "Wholesale Hardware, Plumbing, Heating Equipment": "1178",
    "Wholesale Machinery": "1187",
    Caterers: "2212",
    "Mobile Food Services": "2214",
    "Renewable Energy Power Generation": "3240",
    "Bars, Taverns, and Nightclubs": "2217",
    "Renewable Energy Equipment Manufacturing": "3241",
    "Engineering Services": "3242",
    "Services for Renewable Energy": "3243",
    "Digital Accessibility Services": "3244",
    "Accessible Hardware Manufacturing": "3245",
    "Accessible Architecture and Design": "3246",
    "Robot Manufacturing": "3247",
    "Robotics Engineering": "3248",
    "Repair and Maintenance": "2225",
    "Surveying and Mapping Services": "3249",
    "Vehicle Repair and Maintenance": "2226",
    "Retail Pharmacies": "3250",
    "Climate Technology Product Manufacturing": "3251",
    "Climate Data and Analytics": "3252",
    "Alternative Fuel Vehicle Manufacturing": "3253",
    "Wholesale Recyclable Materials": "1206",
    "Smart Meter Manufacturing": "3254",
    "Fuel Cell Manufacturing": "3255",
    "Wholesale Luxury Goods and Jewelry": "1208",
    "Regenerative Design": "3256",
    "Wholesale Paper Products": "1212",
    "Electronic and Precision Equipment Maintenance": "2240",
    "Wholesale Drugs and Sundries": "1221",
    "Wholesale Apparel and Sewing Supplies": "1222",
    "Commercial and Industrial Machinery Maintenance": "2247",
    "Farming, Ranching, Forestry": "201",
    "Reupholstery and Furniture Repair": "2253",
    "Wholesale Footwear": "1230",
    "Wholesale Food and Beverage": "1231",
    "Footwear and Leather Goods Repair": "2255",
    "Personal and Laundry Services": "2258",
    "Personal Care Services": "2259",
    "Laundry and Drycleaning Services": "2272",
    "Wholesale Raw Farm Products": "1250",
    "Wholesale Chemical and Allied Products": "1257",
    "Pet Services": "2282",
    "Wholesale Petroleum and Petroleum Products": "1262",
    "Wholesale Alcoholic Beverages": "1267",
    "Ranching and Fisheries": "256",
    "Internet Marketplace Platforms": "1285",
    "Retail Motor Vehicles": "1292",
    "Household Services": "2318",
    "Retail Furniture and Home Furnishings": "1309",
    "Retail Appliances, Electrical, and Electronic Equipment": "1319",
    "Forestry and Logging": "298",
    "Retail Building Materials and Garden Equipment": "1324",
    "Health and Human Services": "2353",
    "Public Health": "2358",
    "Public Assistance Programs": "2360",
    "Food and Beverage Retail": "1339",
    "Air, Water, and Waste Program Management": "2366",
    "Conservation Programs": "2368",
    "Housing and Community Development": "2369",
    "Community Development and Urban Planning": "2374",
    "Economic Programs": "2375",
    "Oil, Gas, and Mining": "332",
    "Retail Health and Personal Care Products": "1359",
    "Coal Mining": "341",
    "Military and International Affairs": "2391",
    "Metal Ore Mining": "345",
    "Retail Gasoline": "1370",
    "Operations Consulting": "2401",
    "Nonmetallic Mineral Mining": "356",
    "Electric Power Transmission, Control, and Distribution": "382",
    "Retail Musical Instruments": "1407",
    "Electric Power Generation": "383",
    "Hydroelectric Power Generation": "384",
    "Retail Books and Printed News": "1409",
    "Fossil Fuel Electric Power Generation": "385",
    "Nuclear Electric Power Generation": "386",
    "Solar Electric Power Generation": "387",
    "Environmental Quality Programs": "388",
    "Geothermal Electric Power Generation": "389",
    "Biomass Electric Power Generation": "390",
    "Natural Gas Distribution": "397",
    "Water, Waste, Steam, and Air Conditioning Services": "398",
    "Retail Florists": "1423",
    "Retail Office Supplies and Gifts": "1424",
    "Water Supply and Irrigation Systems": "400",
    "Steam and Air-Conditioning Supply": "404",
    "Building Construction": "406",
    "Retail Recyclable Materials & Used Merchandise": "1431",
    "Residential Building Construction": "408",
    "Data Infrastructure and Analytics": "2458",
    "Nonresidential Building Construction": "413",
    "Utility System Construction": "419",
    "Electrical Equipment Manufacturing": "2468",
    "Online and Mail Order Retail": "1445",
    "Subdivision of Land": "428",
    "Highway, Street, and Bridge Construction": "431",
    "Specialty Trade Contractors": "435",
    "Building Structure and Exterior Contractors": "436",
    "Wind Electric Power Generation": "2489",
    Wineries: "2500",
    "Building Equipment Contractors": "453",
    "Rail Transportation": "1481",
    "Building Finishing Contractors": "460",
    "Ground Passenger Transportation": "1495",
    "Urban Transit Services": "1497",
    "Interurban and Rural Bus Services": "1504",
    "Taxi and Limousine Services": "1505",
    "Animal Feed Manufacturing": "481",
    "School and Employee Bus Services": "1512",
    "Shuttles and Special Needs Transportation Services": "1517",
    "Sugar and Confectionery Product Manufacturing": "495",
    "Pipeline Transportation": "1520",
    "Fruit and Vegetable Preserves Manufacturing": "504",
    "Sightseeing Transportation": "1532",
    "Meat Products Manufacturing": "521",
    "Seafood Product Manufacturing": "528",
    "Baked Goods Manufacturing": "529",
    "Postal Services": "1573",
    Breweries: "562",
    Distilleries: "564",
    "Technology, Information and Media": "1594",
    "Periodical Publishing": "1600",
    "Book Publishing": "1602",
    "Movies and Sound Recording": "1611",
    "Apparel Manufacturing": "598",
    "Sound Recording": "1623",
    "Sheet Music Publishing": "1625",
    "Radio and Television Broadcasting": "1633",
    "Fashion Accessories Manufacturing": "615",
    "Leather Product Manufacturing": "616",
    "Cable and Satellite Programming": "1641",
    "Telecommunications Carriers": "1644",
    "Footwear Manufacturing": "622",
    "Satellite Telecommunications": "1649",
    "Women's Handbag Manufacturing": "625",
    "Credit Intermediation": "1673",
    "Savings Institutions": "1678",
    "Loan Brokers": "1696",
    "Oil and Coal Product Manufacturing": "679",
    "Securities and Commodity Exchanges": "1713",
    "Chemical Raw Materials Manufacturing": "690",
    "Investment Advice": "1720",
    "Insurance Carriers": "1725",
    "Artificial Rubber and Synthetic Fiber Manufacturing": "703",
    "Agricultural Chemical Manufacturing": "709",
    "Insurance Agencies and Brokerages": "1737",
    "Claims Adjusting, Actuarial Services": "1738",
    "Funds and Trusts": "1742",
    "Insurance and Employee Benefit Funds": "1743",
    "Pension Funds": "1745",
    "Paint, Coating, and Adhesive Manufacturing": "722",
    "Trusts and Estates": "1750",
    "Soap and Cleaning Product Manufacturing": "727",
    "Real Estate and Equipment Rental Services": "1757",
    "Leasing Residential Real Estate": "1759",
    "Plastics and Rubber Product Manufacturing": "743",
    "Real Estate Agents and Brokers": "1770",
    "Equipment Rental Services": "1779",
    "Consumer Goods Rental": "1786",
    "Rubber Products Manufacturing": "763",
    "Clay and Refractory Products Manufacturing": "773",
    "Commercial and Industrial Equipment Rental": "1798",
    "Glass Product Manufacturing": "779",
    "Wood Product Manufacturing": "784",
    "Professional Services": "1810",
    "Lime and Gypsum Products Manufacturing": "794",
    "Abrasives and Nonmetallic Minerals Manufacturing": "799",
    "Primary Metal Manufacturing": "807",
    "IT System Design Services": "1855",
    "Marketing Services": "1862",
    "Fabricated Metal Products": "840",
    "Cutlery and Handtool Manufacturing": "849",
    "Architectural and Structural Metal Manufacturing": "852",
    "Boilers, Tanks, and Shipping Container Manufacturing": "861",
    "Construction Hardware Manufacturing": "871",
    "Spring and Wire Product Manufacturing": "873",
    "Turned Products and Fastener Manufacturing": "876",
    "Holding Companies": "1905",
    "Metal Treatments": "883",
    "Industry Associations": "1909",
    "Landscaping Services": "2934",
    "Professional Organizations": "1911",
    "Metal Valve, Ball, and Roller Manufacturing": "887",
    "Administrative and Support Services": "1912",
    "Office Administration": "1916",
    "Executive Search Services": "1923",
    "Temporary Help Services": "1925",
    "Agriculture, Construction, Mining Machinery Manufacturing": "901",
    "Telephone Call Centers": "1931",
    "Collection Agencies": "1938",
    "Commercial and Service Industry Machinery Manufacturing": "918",
    "HVAC and Refrigeration Equipment Manufacturing": "923",
    "Metalworking Machinery Manufacturing": "928",
    "Security Guards and Patrol Services": "1956",
    "Security Systems Services": "1958",
    "Engines and Power Transmission Equipment Manufacturing": "935",
    "Janitorial Services": "1965",
    "Waste Collection": "1981",
    "Waste Treatment and Disposal": "1986",
    "Communications Equipment Manufacturing": "964",
    "Audio and Video Equipment Manufacturing": "973",
    Education: "1999",
    "Measuring and Control Instrument Manufacturing": "983",
    "Secretarial Schools": "2012",
    "Technical and Vocational Training": "2018",
    "Magnetic and Optical Media Manufacturing": "994",
    "Cosmetology and Barber Schools": "2019",
    "Flight Training": "2020",
    "Electric Lighting Equipment Manufacturing": "998",
    "Fine Arts Schools": "2025",
    "Sports and Recreation Instruction": "2027",
    "Household Appliance Manufacturing": "1005",
    "Language Schools": "2029",
    Physicians: "2040",
    "Courts of Law": "3065",
    "Correctional Institutions": "3068",
    Dentists: "2045",
    "Fire Protection": "3070",
};

export const jobFunctionOptions = {
    "Business Development": "4",
    Engineering: "8",
    Accounting: "1",
    Administrative: "2",
    "Arts and Design": "3",
    "Community and Social Services": "5",
    Consulting: "6",
    Education: "7",
    Entrepreneurship: "9",
    Finance: "10",
    "Healthcare Services": "11",
    "Human Resources": "12",
    "Information Technology": "13",
    Legal: "14",
    Marketing: "15",
    "Media and Communication": "16",
    "Military and Protective Services": "17",
    Operations: "18",
    "Product Management": "19",
    "Program and Project Management": "20",
    Purchasing: "21",
    "Quality Assurance": "22",
    "Real Estate": "23",
    Research: "24",
    Sales: "25",
    "Customer Success and Support": "26",
};
